import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import api from '../../services/api';
import jwt from 'jsonwebtoken';
import { cpfMask, dateMask, cardNumberMask, phoneNumberMask } from '../../utils/inputMask';
import '../HomeDashboard/dashboard.css';
import {
    PageContainer,
    ModalContainer,
    TopSection,
    DataFieldsContainer,
    BottomBar,
    CardFieldsContainer,
    CardInputsContainer,
    NextButton,
    PreviousButton,
    RegularText,
    FeatureText,
    FeatureTitle,
    SectionTitle,
    TitleText,
    InputContainer,
    TextInput,
    HalfInput,
    OptionsContainer,
    OptionListItem,
    OptionList,
    InstallmentField
} from './style';
import LoadingState from '../../components/LoadingState';
import Card from '../../components/Card';
import SubscriptionPlan from '../../components/SubscriptionPlan';
import background_logo from '../../assets/images/logo-faded.svg';
import checkmark from '../../assets/images/checkmark.svg';
import arrow_down from '../../assets/images/icons/arrow-down.svg';

function PaymentPage() {
    const [isLoading, setIsLoading] = useState(false);
    const [showInstallments, setShowInstallments] = useState(false);
    const [step, setStep] = useState(0);
    const [token, setToken] = useState("");

    const [doctorPlans, setDoctorPlans] = useState([]);
    const [selectedPlanId, setSelectedPlanId] = useState();
    const [installmentOptions, setInstallmentOptions] = useState([]);
    const [selectedInstallment, setSelectedInstallment] = useState("");

    const [userName, setUserName] = useState("");
    const [userCPF, setUserCPF] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [userPhone, setUserPhone] = useState("+55 ");

    const [cardNumber, setCardNumber] = useState("");
    const [cardExpireDate, setCardExpireDate] = useState("");
    const [cardCVV, setCardCVV] = useState("");
    const [cardName, setCardName] = useState("");
    const [cardCPF, setCardCPF] = useState("");

    const [errorMessage, setErrorMessage] = useState("");


    function validCPF(strCPF) {
        const cpf = strCPF.replace("-", '').replace(".", '').replace(".", '');
        let Soma = 0;
        let Resto;

        if (cpf == "00000000000") return false;

        for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);

        Resto = (Soma * 10) % 11;

        if ((Resto == 10) || (Resto == 11)) Resto = 0;

        if (Resto != parseInt(cpf.substring(9, 10))) return false;

        Soma = 0;

        for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);

        Resto = (Soma * 10) % 11;

        if ((Resto == 10) || (Resto == 11)) Resto = 0;

        if (Resto != parseInt(cpf.substring(10, 11))) return false;

        return true;
    }

    const validEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const validPersonalData = () => {
        if (userName && userPhone && userCPF && userEmail) {
            if (userPhone.length < 14) {
                setErrorMessage("*Informe um número de telefone válido");
                return false;
            }
            if (!validCPF(userCPF)) {
                setErrorMessage("*Informe um CPF válido");
                return false;
            }
            if (typeof userName.split(" ")[1] === "undefined") {
                setErrorMessage("*Informe seu nome completo");
                return false;
            }
            if (!validEmail(userEmail)) {
                setErrorMessage("*Informe um email válido");
                return false;
            }

            return true;
        } else {
            setErrorMessage("*Preencha todos os campos");
            return false;
        }
    };

    const validCardData = () => {
        if (cardNumber && cardExpireDate && cardCVV && cardName) {
            if (cardNumber.length < 19) {
                setErrorMessage("*Informe um número de cartão válido");
                return false;
            }

            const currentYear = new Date().getFullYear() % 100;
            const currentMonth = new Date().getMonth() + 1;

            if (cardExpireDate.length < 5 ||
                parseInt(cardExpireDate.split("/")[0]) > 12 ||
                parseInt(cardExpireDate.split("/")[0]) <= 0 ||
                parseInt(cardExpireDate.split("/")[1]) < currentYear ||
                (parseInt(cardExpireDate.split("/")[1]) === currentYear &&
                    parseInt(cardExpireDate.split("/")[0]) < currentMonth)) {
                setErrorMessage("*Informe um data válida");
                return false;
            }

            if (!validCPF(cardCPF)) {
                setErrorMessage("*Informe um CPF válido");
                return false;
            }

            return true;
        }

        setErrorMessage("*Preencha todos os campos");
        return false;
    };

    function nextStep() {
        return step + 1 < 4 ? step + 1 : 4;
    }

    function previousStep() {
        return step - 1 >= 0 ? step - 1 : 0;
    }

    const getPlanInstallments = async (planId) => {
        setIsLoading(true);
        try {
            const res = await api.get(`/doctor_plans/${planId}/installments/`);
            setInstallmentOptions(res.data);
            setSelectedInstallment(res.data[0]);

        } catch (error) {
            console.log(error);
        }
        setIsLoading(false);
    }

    const handleSelectPlan = async (plan) => {
        setSelectedPlanId(plan.id);
        await getPlanInstallments(plan.id);
        setStep(nextStep());
    }

    const InstallmentOptions = () => {
        return installmentOptions.map((option) => {
            return (
                <OptionListItem
                    style={{ outline: "none" }}
                    onClick={() => {
                        setSelectedInstallment(option)
                        setShowInstallments(false)
                    }}
                    key={option.installments.toString()}
                >
                    {`${option.installments}x de R$ ${option.installment_value}`}
                </OptionListItem>
            );
        });
    };

    const Features = () => {
        const features = [
            {
                title: "Agendamento",
                description: "O profissional disponibiliza os horários de atendimento via Telemedicina Arquimed e seu paciente pode marcar a própria consulta.",
            },
            {
                title: "Vídeo, áudio",
                description: "Todas as consultas são gravadas áudio e vídeo, criptografadas e armazenadas com segurança.",
            },
            {
                title: "Fila de atendimento",
                description: "Caso a instituição utilize a Telemedicina em Pronto Atendimento, os pacientes serão atendidos por ordem de acesso.",
            },
            {
                title: "Prontuário digital",
                description: "Paciente e médicos têm acesso às anotações feitas durante a consulta a qualquer momento via Internet.",
            },
            {
                title: "Evolução médica",
                description: "Todo histórico do seu paciente fica salvo na Plataforma, independente do profissional que o atendeu.",
            },
            {
                title: "Prescrição e atestado",
                description: "Emita receitas e atestados de modo online, seguro e com validade legal pela Plataforma com sua assinatura digital.",
            },
            {
                title: "Triagem e classificação",
                description: "Triagem realizada já no agendamento da consulta.",
            },
            {
                title: "Upload de documentos",
                description: "Durante a consulta, o paciente pode anexar ao sistema, laudos e exames anteriores para auxiliar o médico no diagnóstico.",
            },
            {
                title: "Encaminhamento",
                description: "Durante a Teleconsulta, o profissional pode encaminhar seu paciente a outro especialista pela própria plataforma.",
            },
            {
                title: "Pagamento online",
                description: "Nosso marketplace de pagamentos próprio, aceita cartão de crédito e faz o repasse ao médico automaticamente.",
            },
            {
                title: "Relatórios gerenciais",
                description: "Acesse relatórios de atendimentos, faturamento com filtros personalizados e importe na extensão desejada.",
            },
        ];

        return features.map((feature) => {
            return (
                <div
                    style={{ marginBlock: ".75rem", display: "flex", flexDirection: "column", alignItems: "center", width: "100%" }}
                    key={feature.title}
                >
                    <FeatureTitle>{feature.title}</FeatureTitle>
                    <FeatureText>{feature.description}</FeatureText>
                </div>
            );
        });
    };

    const PlanOptions = () => {
        return (
            <div style={{ width: "100%", display: "flex", justifyContent: "space-evenly" }}>
                {
                    doctorPlans
                        .filter((plan) => {
                            if (plan.status === "ACTIVE") return plan;
                        })
                        .map((plan) => {
                            return (
                                <SubscriptionPlan
                                    id={plan.id}
                                    description={plan.description}
                                    interval={plan.interval}
                                    selectFunction={() => {
                                        handleSelectPlan(plan)
                                    }}
                                    key={plan.id.toString()}
                                />
                            );
                        })
                }
            </div>
        );
    };

    const preRegisterUser = async () => {
        setIsLoading(true);
        try {
            const res = await api.post('/pre_register/', {
                name: userName,
                email: userEmail,
                cpf: userCPF.replace("-", '').replace(".", '').replace(".", ''),
                country_code: userPhone.split(" ")[0].replace("+", ""),
                area_code: userPhone.split(" ")[1].replace("(", "").replace(")", ""),
                base_phonenumber: userPhone.split(" ")[2].replace("-", ""),
                plan: selectedPlanId,
                card_number: cardNumber.replace("-", "").replace("-", "").replace("-", ""),
                holder_name: cardName,
                holder_document: cardCPF.replace("-", '').replace(".", '').replace(".", ''),
                cvv: cardCVV,
                exp_month: cardExpireDate.split('/')[0],
                exp_year: "20" + cardExpireDate.split('/')[1],
                installments: parseInt(selectedInstallment.installments)
            });

            console.log(res.data);
            const newToken = jwt.sign(
                {
                    id: res.data.doctor,
                    authorization: res.data.authorization_code,
                    email: userEmail,
                },
                "telemedfront",
            );

            setToken(newToken);

            setIsLoading(false);
            setStep(nextStep());
        } catch (error) {
            console.log(error);
        }
    }

    const getDoctorPlans = async () => {
        setIsLoading(true);
        try {
            const res = await api.get("/doctor_plans/");
            setDoctorPlans(res.data);
        } catch (error) {
            console.log(error);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        getDoctorPlans();
    }, []);

    return (
        <PageContainer>
            <ModalContainer style={{
                backgroundImage: `url(${background_logo})`,
                backgroundRepeat: "no-repeat",
            }}>
                {
                    step === 0 ? (
                        <>
                            <TopSection style={{ padding: "10vh 0", justifyContent: "flex-start" }}>
                                <TitleText style={{ marginBottom: "3rem" }}>Bem-vindo a Telemedicina Arquimed, seu consultório virtual</TitleText>
                                <RegularText style={{ width: "75%", marginBottom: "1.5rem" }}>
                                    A Telemedicina Arquimed é uma solução em saúde a distância que possibilita que
                                    consultas sejam realizadas através da Internet a qualquer hora e de qualquer lugar.
                                    Com uma interface amigável e de fácil compartilhamento das informações e anotações dos seus pacientes.
                                    Nosso software de Telemedicina foi projetado para conectar pacientes, médicos e instituições de saúde
                                    com praticidade e segurança, dando um novo significado a Medicina Presente.

                                </RegularText>
                                <TitleText style={{ marginBottom: "1rem", fontSize: "1.2rem" }}>Conheça um pouco mais sobre a Telemedicina Arquimed.</TitleText>
                                <Features />
                            </TopSection>

                            <BottomBar>
                                <Link
                                    to="/"
                                >
                                    <PreviousButton
                                        style={{ outline: "none" }}
                                        onClick={() => setStep(previousStep())}
                                    >
                                        Voltar
                                    </PreviousButton>
                                </Link>
                                <NextButton
                                    style={{ outline: "none" }}
                                    onClick={() => setStep(nextStep())}
                                >
                                    Prosseguir
                                </NextButton>
                            </BottomBar>
                        </>
                    ) : null
                }

                {step === 1 ? (
                    <>
                        {
                            isLoading ? (
                                <TopSection style={{ height: "50vh", alignItems: "center" }}>
                                    <LoadingState />
                                </TopSection>
                            ) : (
                                <TopSection style={{ height: "100%", padding: "9vh 0" }}>
                                    <TitleText style={{ marginBottom: "3rem" }}>Escolha o melhor plano para você!</TitleText>

                                    <PlanOptions />
                                </TopSection>
                            )
                        }
                    </>
                ) : null}
                {step === 2 ? (
                    <>
                        <TopSection>
                            <DataFieldsContainer>
                                <SectionTitle style={{ marginBottom: "min(2rem, 2vh)" }}>Dados Pessoais</SectionTitle>

                                <div style={{ height: "80%", width: "100%", display: "flex", flexDirection: "column", justifyContent: "space-around", alignItems: "flex-start" }}>
                                    <InputContainer>
                                        <RegularText>Nome Completo</RegularText>
                                        <TextInput
                                            onChange={(e) => setUserName(e.target.value)}
                                            value={userName}
                                        />
                                    </InputContainer>

                                    <InputContainer>
                                        <RegularText>CPF</RegularText>
                                        <TextInput
                                            onChange={e => setUserCPF(cpfMask(e.target.value))}
                                            value={userCPF}
                                        />
                                    </InputContainer>

                                    <InputContainer>
                                        <RegularText>Email</RegularText>
                                        <TextInput
                                            onChange={e => setUserEmail(e.target.value)}
                                            value={userEmail}
                                        />
                                    </InputContainer>

                                    <InputContainer>
                                        <RegularText>Telefone</RegularText>
                                        <TextInput
                                            onChange={e => setUserPhone(phoneNumberMask(e.target.value))}
                                            value={userPhone}
                                        />
                                    </InputContainer>
                                </div>
                            </DataFieldsContainer>
                            <div style={{ color: "red", width: "95%", display: "flex", justifyContent: "flex-end" }}>
                                {errorMessage}
                            </div>
                        </TopSection>
                        <BottomBar>
                            <PreviousButton
                                style={{ outline: "none" }}
                                onClick={() => {
                                    setErrorMessage("")
                                    setStep(previousStep())
                                }}
                            >
                                Voltar
                            </PreviousButton>
                            <NextButton
                                style={{ outline: "none" }}
                                onClick={() => {
                                    if (validPersonalData()) {
                                        setErrorMessage("")
                                        setStep(nextStep())
                                    }
                                }}
                            >
                                Prosseguir
                            </NextButton>
                        </BottomBar>
                    </>

                ) : null}
                {
                    step === 3 ? (
                        <>
                            {
                                isLoading ? (
                                    <TopSection style={{ height: "50vh", alignItems: "center" }}>
                                        <LoadingState />
                                    </TopSection>
                                ) : (

                                    <>
                                        <TopSection style={{ padding: "0", overflowY: "visible" }}>
                                            <DataFieldsContainer>
                                                <SectionTitle style={{ marginBottom: "min(1rem, 1vh)" }}>Adicionar Cartão</SectionTitle>

                                                <CardFieldsContainer>

                                                    <CardInputsContainer>
                                                        <InputContainer>
                                                            <RegularText>Número do cartão</RegularText>
                                                            <TextInput
                                                                onChange={e => {
                                                                    setCardNumber(cardNumberMask(e.target.value));
                                                                }}
                                                                value={cardNumber} />
                                                        </InputContainer>

                                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                            <HalfInput>
                                                                <RegularText>Validade</RegularText>
                                                                <TextInput
                                                                    onChange={e => setCardExpireDate(dateMask(e.target.value))}
                                                                    value={cardExpireDate} />
                                                            </HalfInput>

                                                            <HalfInput>
                                                                <RegularText>CVV</RegularText>
                                                                <TextInput
                                                                    onChange={e => {
                                                                        const re = /^[0-9\b]+$/;
                                                                        if (e.target.value === '' || re.test(e.target.value)) {
                                                                            setCardCVV(e.target.value);
                                                                        }
                                                                    }}
                                                                    value={cardCVV}
                                                                    maxLength={3} />
                                                            </HalfInput>
                                                        </div>

                                                        <InputContainer>
                                                            <RegularText style={{ lineClamp: "1" }}>Nome do titular como no cartão</RegularText>
                                                            <TextInput
                                                                onChange={e => setCardName(e.target.value)}
                                                                value={cardName} />
                                                        </InputContainer>

                                                        <InputContainer>
                                                            <RegularText style={{ lineClamp: "1" }}>CPF do titular do cartão</RegularText>
                                                            <TextInput
                                                                onChange={e => setCardCPF(cpfMask(e.target.value))}
                                                                value={cardCPF} />
                                                        </InputContainer>

                                                        <InputContainer style={{ paddingBottom: "0" }}>
                                                            <RegularText>Parcelas</RegularText>
                                                            <InstallmentField style={{ outline: "none" }} onClick={() => setShowInstallments(!showInstallments)}>
                                                                {`${selectedInstallment.installments}x de R$ ${selectedInstallment.installment_value}`}
                                                                <img src={arrow_down} style={{
                                                                    width: "min(7.5%, 20px)",
                                                                    transform: showInstallments ? "" : "rotate(-90deg)",
                                                                    transition: "100ms",
                                                                }} />
                                                            </InstallmentField>
                                                        </InputContainer>
                                                        {
                                                            showInstallments ? (
                                                                <OptionsContainer>
                                                                    <OptionList>
                                                                        <InstallmentOptions />
                                                                    </OptionList>
                                                                </OptionsContainer>
                                                            ) : null
                                                        }


                                                    </CardInputsContainer>
                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                        <Card
                                                            name={cardName}
                                                            date={cardExpireDate}
                                                            cvv={cardCVV}
                                                            number={cardNumber}
                                                        />
                                                    </div>
                                                </CardFieldsContainer>
                                            </DataFieldsContainer>
                                            <div style={{ color: "red", width: "95%", display: "flex", justifyContent: "flex-end" }}>
                                                {errorMessage}
                                            </div>
                                        </TopSection>
                                        <BottomBar>
                                            <PreviousButton
                                                style={{ outline: "none" }}
                                                onClick={() => {
                                                    setErrorMessage("");
                                                    setStep(previousStep());
                                                }}
                                            >
                                                Voltar
                                            </PreviousButton>
                                            <NextButton
                                                style={{ outline: "none" }}
                                                onClick={() => {
                                                    if (validCardData()) preRegisterUser();
                                                    else console.log("preencha os campos");
                                                }}
                                            >
                                                Finalizar
                                            </NextButton>
                                        </BottomBar>
                                    </>
                                )
                            }
                        </>

                    ) : null
                }
                {
                    step === 4 ? (
                        <>
                            <TopSection style={{ height: "100%", padding: "5vh 0" }}>
                                <img style={{ width: "max(100px,10vw)", margin: "1vw" }} src={checkmark} />
                                <TitleText style={{ color: "#39B54A" }}>
                                    {`Pagamento realizado com sucesso!`}
                                </TitleText>
                            </TopSection>

                            <BottomBar style={{ justifyContent: "center" }}>
                                <Link to={`signup/${token}`}>
                                    <NextButton
                                        style={{ outline: "none" }}
                                    >
                                        Concluir
                                    </NextButton>
                                </Link>
                            </BottomBar>
                        </>
                    ) : null
                }
            </ModalContainer>
        </PageContainer >

    );
}

export default PaymentPage;