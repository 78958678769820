import React, { Component } from "react";
import "./modal.css"
import { Modal, Button } from 'react-bootstrap';
import { getDay } from "date-fns";

import api from "../../services/api"
import { getUserId, getToken } from "../../services/auth";
import PaginationComponent from '../../components/PaginationComponent';

class ModalComponent extends Component {

    constructor(props) {
        super(props);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);

        this.deleteHorario = this.deleteHorario.bind(this);
        this.getHorarios = this.getHorarios.bind(this);
    }

    state = {
        modalIsOpen: false,
        formdict: {
            'Sunday': false,
            'Monday': false,
            'Tuesday': false,
            'Wednesday': false,
            'Thursday': false,
            'Friday': false,
            'Saturday': false,
        },
        inicio: "00:00",
        final: "00:30",
        error: "",
        invalid: true,
        horarios: "",
        agenda: {},
    }

    handleAgenda = async e => {
        e.preventDefault();
        let days_ok = Object.keys(this.state.formdict).filter(((key) => {
            if (this.state.formdict[key])
                return key;
        }));

        try {
            if (this.state.invalid)
                throw new Error("Dados de dia vazio.")
            let data = {
                "dayname": days_ok,
                "doctor": this.props.doctor.email,
                "begin": this.state.inicio,
                "end": this.state.final
            };

            await api.post("/agenda_in_range/", data, {
                headers: {
                    'Authorization': `JWT ${getToken()}`
                }
            }).then(() => { this.getHorarios(); })


        } catch (err) {
            try {
                this.setState({ "error": "Erro: " + err.response.data["error"] });
            } catch {
                this.setState({ "error": "Erro: " + String(err) });
            }
        }

    }

    async openModal() {
        await this.getHorarios();
        this.setState({ modalIsOpen: true });

        let array = {
            1: 'seg',
            2: 'ter',
            3: 'qua',
            4: 'qui',
            5: 'sex',
            6: 'sab',
            7: 'dom',
        }

        if(this.refs[array[getDay(new Date())]]){
            this.refs[array[getDay(new Date())]].click();
        }
    }

    closeModal() {
        this.setState({ modalIsOpen: false });
    }

    changeCheckState(param) {
        this.state.formdict[param] = !this.state.formdict[param];
        document.getElementById(param).checked = this.state.formdict[param];
        this.checkEmpty()
    }

    createOptionHorarios() {
        let arr = ['00:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30', '05:00', '05:30', '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30']
        let ret = arr.map((el) => {
            return <option key={el}>{el}</option>
        }
        )
        return ret;
    }

    checkEmpty() {
        this.setState({
            invalid: (Object.values(this.state.formdict).filter((el) => { return el === true }).length === 0)
        });
    }

    async deleteHorario(id) {
        await api.delete("/times/" + id + "/").then(() => {
            this.getHorarios();
        })
            .catch(err => {
                console.log(err);
            });
    }

    async getHorarios() {
        await api.get("/doctors/" + getUserId() + "/").then((res) => {
            let data = res.data;
            this.setState({
                agenda: data.doctor_agenda
            });
        });
        let objeto = this.state.agenda
        let ret = objeto ? this.buildAgendaDiv(objeto) : [];

        this.setState({
            horarios: ret
        })
    }

    buildAgendaDiv(objeto) {
        let x = Object.keys(objeto).map(el => {
            if (el === "day_agenda") {
                let m = objeto[el].map((elx, index) => {
                    let v = elx;

                    return (
                        <div key={elx.dayname} className={`tab${index + 1}`}>
                            <div className="space"></div>
                            <PaginationComponent todos={v.daytime} class="text-xs-center" func={this.deleteHorario} />
                        </div>
                    );

                });

                return m;
            }
        });
        return x;
    }

    render() {
        let optionHorarios = this.createOptionHorarios();

        return (
            <div className="teste">
                <button onClick={this.openModal} className="btn btn-success botaotop">
                    <i className="fa fa-calendar" />
                    &nbsp;Configurar Horário
                </button>
                <Modal show={this.state.modalIsOpen} size="lg" onHide={this.closeModal} centered>
                    <div className="modal-content">
                        <Modal.Header>
                            <Modal.Title className="modal-title modaltitulo">Horários de atendimento</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <div name="cadastrados">

                                <div className="pc-tab">

                                    <h1 className="titleHC">Horários cadastrados</h1>

                                    <input id="tab1" type="radio" name="pct" />
                                    <input id="tab2" type="radio" name="pct" />
                                    <input id="tab3" type="radio" name="pct" />
                                    <input id="tab4" type="radio" name="pct" />
                                    <input id="tab5" type="radio" name="pct" />
                                    <input id="tab6" type="radio" name="pct" />
                                    <input id="tab7" type="radio" name="pct" />

                                    <div className="guia">
                                        <ul>
                                            <li className="tab1" >
                                                <label for="tab1" ref='dom'>DOM</label>
                                            </li>
                                            <li className="tab2" >
                                                <label for="tab2" ref='seg'>SEG</label>
                                            </li>
                                            <li className="tab3" >
                                                <label for="tab3" ref='ter'>TER</label>
                                            </li>
                                            <li className="tab4" >
                                                <label for="tab4" ref='qua'>QUA</label>
                                            </li>
                                            <li className="tab5" >
                                                <label for="tab5" ref='qui'>QUI</label>
                                            </li>
                                            <li className="tab6" >
                                                <label for="tab6" ref='sex'>SEX</label>
                                            </li>
                                            <li className="tab7" >
                                                <label for="tab7" ref='sab'>SAB</label>
                                            </li>
                                        </ul>
                                    </div>

                                    <section>
                                        {this.state.horarios}
                                    </section>
                                </div>
                            </div>



                            <form onSubmit={this.handleAgenda}>
                                <div className="novaagenda">
                                    <div className="pc-tab2">
                                        <div className="col-xs-12 text-center">
                                            <h1 className='titleHC'>Criar novo horário</h1>
                                        </div>
                                        <h5 className="textInfo">Selecione o dia:</h5>
                                        <div id='menu-semana'>
                                            <div><input type="checkbox" id="Sunday" className="botaosemana" /><div onClick={() => this.changeCheckState("Sunday")} className='botao-semana'>DOM</div></div>
                                            <div><input type="checkbox" id="Monday" className="botaosemana" /><div onClick={() => this.changeCheckState("Monday")} className='botao-semana'>SEG</div></div>
                                            <div><input type="checkbox" id="Tuesday" className="botaosemana" /><div onClick={() => this.changeCheckState("Tuesday")} className='botao-semana'>TER</div></div>
                                            <div><input type="checkbox" id="Wednesday" className="botaosemana" /><div onClick={() => this.changeCheckState("Wednesday")} className='botao-semana'>QUA</div></div>
                                            <div><input type="checkbox" id="Thursday" className="botaosemana" /><div onClick={() => this.changeCheckState("Thursday")} className='botao-semana'>QUI</div></div>
                                            <div><input type="checkbox" id="Friday" className="botaosemana" /><div onClick={() => this.changeCheckState("Friday")} className='botao-semana'>SEX</div></div>
                                            <div><input type="checkbox" id="Saturday" className="botaosemana" /><div onClick={() => this.changeCheckState("Saturday")} className='botao-semana'>SAB</div></div>
                                        </div>
                                        <div className="space"></div>

                                        <div id='linha-formulario'>
                                            <h5 className="textInfo">Defina o horário:</h5>
                                            <div className="boxhorario">
                                                <div className='minhas'>
                                                    <label className='label'>Horário Inicial</label>
                                                    <select className='personalizado menor' onChange={e => this.setState({ inicio: e.target.value })}>
                                                        {optionHorarios}
                                                    </select>
                                                </div>

                                                <div className='minhas'>
                                                    <label className='label'>Horário Final</label>
                                                    <select className='personalizado menor' onChange={e => this.setState({ final: e.target.value })}>
                                                        {optionHorarios}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="boxadd">
                                            <div className="posicionaerro danger"><p>{this.state.error}</p></div>
                                            <div className="posicionabotao"><button disabled={this.state.invalid} className="btn btn-success mybotao" >Adicionar Horário</button></div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={this.closeModal} className="btn-primary">Fechar</Button>
                        </Modal.Footer>
                    </div>
                </Modal>
            </div>
        )
    }
}

export default ModalComponent;