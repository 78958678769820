import axios from "axios";
import { getToken } from "./auth";

export const host = 'https://dashboard.arquimedsaude.com.br'; //'https://dashboard.arquimedsaude.com.br'//'http://142.93.11.134:8004'

const api = axios.create({
  baseURL: host
});

api.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `JWT ${token}`;
  }
  return config;
});

export default api;
